
import { Component, Prop, Vue } from 'vue-property-decorator'
import PropertyModal from '@/views/project/webapi/entity-builder-system/components/property-panel/modal/property-modal.vue'
import { BaseModal } from '@/libs/modal-helper'
import { ApiGatewayRouteFilter } from '@/api/maintain/api-gateway-route-filter'
import EnumSelect from '@/components/selector/enum-select.vue'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'
import GatewayRouteFilterType from '@/api/maintain/gateway-route-filter-type'
import { EnumItem } from '@/api/maintain/gateway-route-rule-type'

/// 新增环境变量模特框
@Component({
  name: 'addRouteRule',
  components: { PropertyModal, EnumSelect }
})
export default class AddRouteRuleModal extends Vue implements BaseModal {
  /// 修改时传入环境变量
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  item?: ApiGatewayRouteFilter;

  /// 修改时传入环境变量
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  routeData?: ApiGatewayRoute;

  /// 是否只读
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly?: boolean;

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }

  /// 页面数据
  formData: ApiGatewayRouteFilter = { ...{ order: 0 }, ...this.item };

  /// 匹配规则选择项
  selectItem: EnumItem = { name: '', value: 0, dest: '', type: 'input' };

  GatewayRouteFilterType = GatewayRouteFilterType;

  arg=''

  args: string[]=[]

  validateArgs (rule: any, value: string, callback: Function) {
    if (this.args.length > 0) {
      callback()
    } else {
      callback(new Error('参数不能为空,请先添加参数'))
    }
  }

  /// 校验规则
  rules = {
    filterType: [
      {
        required: true,
        type: 'number',
        message: 'filter类型不能为空',
        trigger: 'change'
      }
    ],
    order: [
      {
        required: true,
        type: 'number',
        message: 'order不能为空',
        trigger: 'blur'
      }
    ],
    args: [
      {
        required: true,
        validator: this.validateArgs
      }
    ]
  };

  /// 初始化args
  created () {
    if (this.item?.args) {
      this.args = this.item.args.split(',')
    }
    const item = this.getEnumItemByValue(this.formData.filterType || 1)
    this.selectItem = item!
  }

  /// 匹配规则类型选择项改变
  handleChange (data: EnumItem) {
    this.selectItem = data
  }

  /// 由枚举值获取枚举项
  getEnumItemByValue (value: number): EnumItem | undefined {
    for (const key in this.GatewayRouteFilterType) {
      if (value === (this.GatewayRouteFilterType as any)[key].value) {
        return (this.GatewayRouteFilterType as any)[key]
      }
    }
  }

  closeTag (index: number) {
    this.args.splice(index, 1)
  }

  addTags () {
    if (this.arg) {
      if (this.arg.includes(',')) {
        this.args = this.args.concat(this.arg.split(','))
      } else {
        this.args.push(this.arg)
      }

      this.arg = ''
    }
  }

  /// 选择器格式化方法
  formatter (item: EnumItem) {
    return item.name + '-' + item.dest
  }

  /// 确认事件
  doSave () {
    this.formData.args = this.args.join(',');
    (this.$refs as any).formValidate.validate((valid: boolean) => {
      if (valid) {
        this.$emit('onOk', this.formData)
      } else {
        this.$Message.error('数据校验失败')
      }
    })
  }

  /// 取消事件
  doCancel () {
    this.$emit('onCancel')
  }
}
