
import { Component, Prop, Vue } from 'vue-property-decorator'
import apiGatewayRouteFilterApi, {
  ApiGatewayRouteFilter
} from '@/api/maintain/api-gateway-route-filter'
import showAddRouteRuleModal from './modals/add-route-rule'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'
import showAddRouteFilterModal from './modals/add-route-filter'
import moment from 'moment'
import _ from 'lodash'

@Component({
  name: 'GateWayFilterTable',
  components: {}
})
export default class GateWayFilterTable extends Vue {
  /// 传入数据
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  routeData?: ApiGatewayRoute;

  /// ip
  @Prop({
    type: String,
    required: true
  })
  ip!: string;

  /// instanceId
  @Prop({
    type: Number,
    required: true
  })
  instanceId!: number;

  /// port
  @Prop({
    type: String,
    required: true
  })
  port!: string;

  /// 是否只读
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly?: boolean;

  tableData: ApiGatewayRouteFilter[] = [];

  created () {
    this.loadData()
  }

  isError (row: ApiGatewayRouteFilter) {
    if (row.modifyTime && row.lastErrorTime) {
      const modify = _.cloneDeep(row.modifyTime) as any
      const error = _.cloneDeep(row.lastErrorTime) as any
      modify.pop()
      error.pop()
      return moment(error).isAfter(moment(modify))
    }
    return false
  }

  /// 加载表格数据
  loadData () {
    if (this.routeData && this.routeData.id) {
      apiGatewayRouteFilterApi
        .getItemByRouteIdByIp(this.ip, this.routeData.id, this.port, this.instanceId)
        .then((res) => {
          this.tableData = res.data || []
          this.$emit('loadFinish', this.tableData.length)
        })
    }
  }

  /// 修改路由规则
  editItem (item: ApiGatewayRouteFilter) {
    showAddRouteFilterModal(
      this.routeData,
      async (item) => {
        item.routeId = this.routeData?.id
        await apiGatewayRouteFilterApi.updateItemByIp(
          this.ip,
          item,
          this.port,
          this.instanceId
        )
        this.$Message.success('操作成功')
        this.loadData()
        return Promise.resolve()
      },
      false,
      item
    )
  }

  /// 删除数据项
  deleteItem (id: string) {
    this.$Modal.confirm({
      title: '提示',
      content: '确定要删除此规则吗？',
      onOk: () => {
        apiGatewayRouteFilterApi
          .deleteItemByIdsByIp(this.ip, [id], this.port, this.instanceId)
          .then(() => {
            this.loadData()
          })
      }
    })
  }

  /// 查看详情
  viewItem (item: ApiGatewayRouteFilter) {
    showAddRouteRuleModal(this.routeData, undefined, true, item)
  }
}
