
import { Component, Prop, Vue } from 'vue-property-decorator'
import PropertyModal from '@/views/project/webapi/entity-builder-system/components/property-panel/modal/property-modal.vue'
import { BaseModal } from '@/libs/modal-helper'
import apiGatewayRouteApi, { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'

/// 新增环境变量模特框
@Component({
  name: 'addRouteRule',
  components: { PropertyModal }
})
export default class AddRouteModal extends Vue implements BaseModal {
  /// 修改时传入环境变量
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  item?: ApiGatewayRoute;

  /// 修改时传入环境变量
  @Prop({
    type: String,
    required: true
  })
  gatewayId!: string;

  /// 修改时传入ip地址
  @Prop({
    type: String,
    required: true
  })
  ip!: string

  /// 修改时传入端口
  @Prop({
    type: String,
    required: true
  })
  port!: string

  /// instanceId
  @Prop({
    type: Number,
    required: true
  })
  instanceId!: number

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }

  uriPrefix = 'http';

  /// 页面数据
  formData: ApiGatewayRoute = { ...this.item } || {};

  /// 路由名称校验
  async validateName (rule: any, value: string, callback: Function) {
    if (!value) {
      return callback(new Error('路由名称不能为空'))
    }
    const res = await apiGatewayRouteApi.getItemsByNameByIp(this.ip, value, this.gatewayId, this.port, this.instanceId)
    if (!res.data || res.data.length < 1) {
      return callback()
    }
    if (res.data.length === 1 && this.item && res.data[0].id === this.item.id) {
      return callback()
    }
    return callback(new Error('路由名称不能重复'))
  }

  /// 校验规则
  rules = {
    name: [{ required: true, validator: this.validateName, trigger: 'blur' }],
    uri: [{ required: true, message: '路由目的uri不能为空', trigger: 'blur' }]
  };

  created () {
    if (this.formData.uri) {
      const strs = this.formData.uri.split('://')
      this.uriPrefix = strs[0]
      this.formData.uri = strs[1]
    }
  }

  /// 确认事件
  doSave () {
    (this.$refs as any).formValidate.validate((valid: boolean) => {
      if (valid) {
        if (this.formData.uri) {
          this.formData.uri = this.uriPrefix + '://' + this.formData.uri
        }
        this.$emit('onOk', this.formData)
      } else {
        this.$Message.error('数据校验失败')
      }
    })
  }

  /// 取消事件
  doCancel () {
    this.$emit('onCancel')
  }
}
