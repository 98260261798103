
import { showModal } from '@/libs/modal-helper'
import AddRouteFilterModal from './add-route-filter.vue'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'
import { ApiGatewayRouteFilter } from '@/api/maintain/api-gateway-route-filter'

/**
 * 显示新增环境变量模块框
 */
export default function showAddRouteFilterModal (routeData?: ApiGatewayRoute, onOk?: (data: ApiGatewayRouteFilter) => Promise<void>, readonly?: boolean, data?: ApiGatewayRouteFilter) {
  showModal<ApiGatewayRouteFilter>(AddRouteFilterModal, {
    props: {
      routeData: routeData,
      readonly: readonly,
      item: data
    }
  }, true, onOk, undefined)
}
