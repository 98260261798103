
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import GatewayRouteRuleType from '@/api/maintain/gateway-route-rule-type'
import apiGatewayRouteApi, { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'
import apiGatewayRouteFilterApi from '@/api/maintain/api-gateway-route-filter'
import GateWayRouteTable from './gateway-route-tables.vue'
import GateWayFilterTable from './gateway-filter-tables.vue'
import showAddRouteModal from './modals/add-route'
import apiGateWayApi from '@/api/maintain/api-gateway'
import apiGatewayRouteRuleApi from '@/api/maintain/api-gateway-route-rule'
import showAddRouteRuleModal from './modals/add-route-rule'
import { getterUserInfo } from '@/store'
import showAddRouteFilterModal from './modals/add-route-filter'

@Component({
  name: 'GateWayTable',
  components: { GateWayRouteTable, GateWayFilterTable }
})
export default class GateWayTable extends Vue {
  /// ip
  @Prop({
    type: String,
    required: true
  })
  ip!: string;

  /// port
  @Prop({
    type: String,
    required: true
  })
  port!: string;

  /// port
  @Prop({
    type: String,
    required: true
  })
  gatewayId!: string;

  /// port
  @Prop({
    type: Number,
    required: true
  })
  instanceId!: number;

  /// 网关路由匹配规则类型
  gatewayRouteRuleType = GatewayRouteRuleType;

  /**
   * 查询条件
   */
  queryBean = {
    page: 1,
    pageSize: 10,
    sorts: ['-modifyTime'],
    gatewayId: this.gatewayId
  };

  /** 总数据量 */
  total = 0;

  expandRowKeys: string[] = [];

  tableData = [
    {
      title: '路由predicates',
      component: 'gateWayRouteTable',
      type: 'pridicate',
      desc: '新增路由predicate'
    },
    {
      title: '路由filter',
      component: 'GateWayFilterTable',
      type: 'filter',
      desc: '新增路由filter'
    }
  ];

  /// 网关路由列表
  gateWayRoutes: ApiGatewayRoute[] = [];

  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateApiGateway
  }

  created () {
    this.loadGateWayRoutes()
  }

  @Watch('gatewayId')
  onChangeGatewayId (id: string) {
    this.queryBean.gatewayId = id
    this.loadGateWayRoutes()
  }

  /** 展开行变化 */
  rowExpand ({ expanded, row }: any) {
    if (expanded) {
      this.expandRowKeys = [row.id + '']
    } else {
      this.expandRowKeys = []
    }
  }

  /// 添加路由
  addRoute () {
    if (this.gatewayId) {
      showAddRouteModal(
        this.instanceId,
        this.gatewayId,
        this.ip,
        this.port,
        async (item) => {
          item.gatewayId = this.gatewayId
          await apiGatewayRouteApi.insertItemByIp(
            this.ip,
            item,
            this.port,
            this.instanceId
          )
          this.loadGateWayRoutes()
          this.$Message.success('操作成功')
          return Promise.resolve()
        }
      )
    }
  }

  /// 清空路由
  clearAll () {
    const ids = this.gateWayRoutes.map((e) => e.id!) || []
    if (ids.length < 1) {
      this.$Message.error('当前页面路由为空')
      return
    }
    this.$Modal.confirm({
      title: '提示',
      content: '确定要清空路由吗？',
      onOk: () => {
        apiGatewayRouteApi
          .deleteItemByIdsByIp(this.ip, ids, this.port, this.instanceId)
          .then(() => {
            this.loadGateWayRoutes()
          })
      }
    })
  }

  /** 分页变化 */
  handlePageChange ({ currentPage, pageSize }: { currentPage: number; pageSize: number }) {
    this.queryBean.page = currentPage
    this.queryBean.pageSize = pageSize
    this.loadGateWayRoutes()
  }

  /** 加载路由信息 */
  loadGateWayRoutes () {
    apiGatewayRouteApi
      .queryByIp(this.queryBean, this.ip, this.port, this.instanceId)
      .then((res) => {
        this.gateWayRoutes = res.data?.items || []
        this.total = res.data?.total || 0
        if (this.expandRowKeys.length > 0) {
          const re = this.gateWayRoutes.find((e) => e.id + '' === this.expandRowKeys[0])
          this.$nextTick(() => {
            (this.$refs.xTable as any).setRowExpand(re, true)
          })
        }
      })
  }

  /// 修改路由
  uptRoute (row?: ApiGatewayRoute) {
    if (row?.gatewayId) {
      showAddRouteModal(
        this.instanceId,
        row.gatewayId,
        this.ip,
        this.port,
        async (item) => {
          const re = await apiGatewayRouteApi.updateItemByIp(
            this.ip,
            item,
            this.port,
            this.instanceId
          )
          row = re.data
          this.$Message.success('操作成功')
          this.loadGateWayRoutes()
          return Promise.resolve()
        },
        row
      )
    }
  }

  /// 添加路由规则
  addRouteRule (row: ApiGatewayRoute) {
    showAddRouteRuleModal(row, async (item) => {
      item.routeId = row?.id
      await apiGatewayRouteRuleApi.insertItemByIp(
        this.ip,
        item,
        this.port,
        this.instanceId
      )
      this.$Message.success('操作成功')
      this.loadGateWayRoutes()
      return Promise.resolve()
    })
  }

  /** 添加数据项 */
  addItem (row: ApiGatewayRoute, type: string) {
    if (type === 'filter') {
      this.addRouteFilter(row)
    } else {
      this.addRouteRule(row)
    }
  }

  /** 添加路由过滤器 */
  addRouteFilter (row: ApiGatewayRoute) {
    showAddRouteFilterModal(row, async (item) => {
      item.routeId = row?.id

      await apiGatewayRouteFilterApi.insertItemByIp(
        this.ip,
        item,
        this.port,
        this.instanceId
      )
      this.$Message.success('操作成功')
      this.loadGateWayRoutes()
      return Promise.resolve()
    })
  }

  /// 删除路由
  delRoute (row?: ApiGatewayRoute) {
    this.$Modal.confirm({
      title: '提示',
      content: '确定要删除此路由吗？',
      onOk: () => {
        if (row && row.id) {
          apiGatewayRouteApi
            .deleteItemByIdsByIp(this.ip, [row.id!], this.port, this.instanceId)
            .then(() => {
              this.loadGateWayRoutes()
              this.$emit('routeChange')
            })
        }
      }
    })
  }
}
