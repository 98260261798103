
import { Component, Prop, Vue } from 'vue-property-decorator'
import apiGatewayRouteRuleApi, {
  ApiGatewayRouteRule
} from '@/api/maintain/api-gateway-route-rule'
import showAddRouteRuleModal from './modals/add-route-rule'
import GatewayRouteRuleType, { EnumItem } from '@/api/maintain/gateway-route-rule-type'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'
import moment from 'moment'
import _ from 'lodash'

@Component({
  name: 'GateWayRouteTable'
})
export default class GateWayTable extends Vue {
  /// 传入数据
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  routeData?: ApiGatewayRoute;

  /// ip
  @Prop({
    type: String,
    required: true
  })
  ip!: string;

  /// instanceId
  @Prop({
    type: Number,
    required: true
  })
  instanceId!: number;

  /// port
  @Prop({
    type: String,
    required: true
  })
  port!: string;

  /// 是否只读
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly?: boolean;

  tableData: ApiGatewayRouteRule[] = [];

  /// 网关路由匹配规则类型
  gatewayRouteRuleType = GatewayRouteRuleType;

  filterCount = 0;

  created () {
    this.loadData()
  }

  /// 加载表格数据
  loadData () {
    if (this.routeData && this.routeData.id) {
      apiGatewayRouteRuleApi
        .getItemByRouteIdByIp(this.ip, this.routeData.id, this.port, this.instanceId)
        .then((res) => {
          this.tableData = res.data || []
        })
    }
  }

  isError (row: ApiGatewayRouteRule) {
    if (row.modifyTime && row.lastErrorTime) {
      const modify = _.cloneDeep(row.modifyTime) as any
      const error = _.cloneDeep(row.lastErrorTime) as any
      modify.pop()
      error.pop()
      return moment(error).isAfter(moment(modify))
    }
    return false
  }

  setFilterCount (count: number) {
    this.filterCount = count
  }

  /// 由枚举值获取枚举项
  getEnumItemByValue (value: number): EnumItem | undefined {
    for (const key in this.gatewayRouteRuleType) {
      if (value === (this.gatewayRouteRuleType as any)[key].value) {
        return (this.gatewayRouteRuleType as any)[key]
      }
    }
  }

  formatRuleValue (data: ApiGatewayRouteRule) {
    if (data.ruleType) {
      const item = this.getEnumItemByValue(data.ruleType!)
      if (item?.type === 'input-kv') {
        return data.ruleValue?.replace(',', '=')
      }
      return data.ruleValue
    }

    return ''
  }

  /// 修改路由规则
  editItem (item: ApiGatewayRouteRule) {
    showAddRouteRuleModal(
      this.routeData,
      async (item) => {
        item.routeId = this.routeData?.id
        await apiGatewayRouteRuleApi.updateItemByIp(
          this.ip,
          item,
          this.port,
          this.instanceId
        )
        this.$Message.success('操作成功')
        this.loadData()
        return Promise.resolve()
      },
      false,
      item
    )
  }

  /// 删除数据项
  deleteItem (id: string) {
    this.$Modal.confirm({
      title: '提示',
      content: '确定要删除此规则吗？',
      onOk: () => {
        apiGatewayRouteRuleApi
          .deleteItemByIdsByIp(this.ip, [id], this.port, this.instanceId)
          .then(() => {
            this.loadData()
          })
      }
    })
  }

  /// 查看详情
  viewItem (item: ApiGatewayRouteRule) {
    showAddRouteRuleModal(this.routeData, undefined, true, item)
  }
}
