import { ApiResult, DataApiResult, requestRawResponse } from '@/libs/http-request'
import { AppInstaceMangerRequestData } from './app-instance-manger'
import { Method } from 'axios'
import { DeployRequestApi } from './deploy-request'

/**
 * API网关
 */
export interface ApiGatewayRouteRule {

  id?: string;

  // 匹配规则类型
  ruleType?: number;

  // 匹配规则
  ruleValue?: string;

  // 匹配规则备注
  memo?: string;

  /// 匹配路由规则类型名
  ruleTypeName?: string;

  // 路由id
  routeId?: string;

  // 创建时间
  createTime?: string;

  // 修改时间
  modifyTime?: string;

  /// 最后报错时间
  lastErrorTime?: string;

}

/**
 * 团队服务请求api接口
 */
class ApiGatewayRouteRuleApi {
  baseUrlPath = '/api/maintain/gateway-instance/'

  /**
    * 通过查询bean查询数据
    * @param query 查询bean数据对象
    */
  public getItemByRouteIdByIp (ip: string, id: string, port: string, instanceId: number): Promise<DataApiResult<ApiGatewayRouteRule[]>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRouteRule/byRouteId/${id}`
    return this.encodeUrlRequest(instanceId, url, 'GET')
  }

  public updateItemByIp (ip: string, item: ApiGatewayRouteRule, port: string, instanceId: number) {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRouteRule/`
    return this.encodeUrlRequest(instanceId, url, 'POST', item)
  }

  /**
    * 删除id列表指定的数据
    * @param ids 记录主键列表
    */
  public deleteItemByIdsByIp (ip: string, ids: string[], port: string, instanceId: number): Promise<ApiResult> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRouteRule/`
    return this.encodeUrlRequest(instanceId, url, 'DELETE', ids)
  }

  /**
    * 新增指定的数据
    * @param data
    */
  public insertItemByIp (ip: string, data: ApiGatewayRouteRule, port: string, instanceId: number): Promise<DataApiResult<ApiGatewayRouteRule>> {
    (data as any).id = undefined
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRouteRule/`
    return this.encodeUrlRequest(instanceId, url, 'PUT', data)
  }

  /** gateway请求 */
  public encodeUrlRequest (instanceId: number, url: string, methods: Method, data?: any) {
    return new Promise<any>((resolve, reject) => {
      requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
        url: `${this.baseUrlPath}encodeRequestUrl/${instanceId}`,
        data: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        }
      }).then((res) => {
        requestRawResponse({
          url: url,
          method: methods,
          headers: {
            'Content-Type': 'application/json',
            Authorization: res.headers[DeployRequestApi.author_gateway_aukey]
          },
          data: data,
          timeout: 5000
        }, false).then((re) => {
          resolve(re.data)
        }).catch((err) => {
          reject(err)
        })
      })
    })
  }
}

export default new ApiGatewayRouteRuleApi()
