
import { showModal } from '@/libs/modal-helper'
import AddRouteRuleModal from './add-route-rule.vue'
import { ApiGatewayRouteRule } from '@/api/maintain/api-gateway-route-rule'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'

/**
 * 显示新增环境变量模块框
 */
export default function showAddRouteRuleModal (routeData?: ApiGatewayRoute, onOk?: (data: ApiGatewayRouteRule) => Promise<void>, readonly?: boolean, data?: ApiGatewayRouteRule) {
  showModal<ApiGatewayRouteRule>(AddRouteRuleModal, {
    props: {
      routeData: routeData,
      readonly: readonly,
      item: data
    }
  }, true, onOk, undefined)
}
