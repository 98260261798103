/// 带有状态机的表单对象
import cryptoTool from '@/libs/crypto-tool'
import { FormContext, FormEvent, FormSchma, FormState } from '@/libs/form-state'
import { dispatchUpdateCheckTage, getterCurTagName } from '@/store'
import { expect } from 'chai'
import _ from 'lodash'
import { interpret, Interpreter, Machine, State, StateMachine } from 'xstate'
// import { DataFormPage, KEY_OPTION_PARAM, KEY_FORMMODE } from './data-form-page'
import storage from '@/libs/local-storage'
import { DataFormPage, KEY_FORMMODE, KEY_OPTION_PARAM } from '@/frame/share/data-form-page'

/**
 * 拥有状态机的实体
 */
export interface HasStateMachineVersionEntity{
  // id
  id?: string;
  /// 状态机版本
  stateMachineVersion?: string;
  /// 当前状态
  state?: number;
  /// 当前状态名称
  stateName?: string;
}

/**
 * 状态表单页 拥有新增，修改，查看状态自动处理
 */
export default abstract class StateDataFormPage<T extends HasStateMachineVersionEntity, C> extends DataFormPage<T> {
  /// 当前页面是否处理于加载中状态
  protected loading=false

  /**
   * 服务器端的状态
   */
   protected abstract serverState: string

  /**
   * 表单状态机服务
   */
  protected formStateSerive?: Interpreter<C, FormSchma, FormEvent, {
    value: any;
    context: C;
  }>

  /**
   * 表单当前状态
   */
  protected formState?: State<C, FormEvent, FormSchma, {
    value: any;
    context: C;
  }>

  /**
   * 当前表单上下文
   */
  get formContext (): FormContext<C, FormEvent> {
    return {
      formState: this.formState,
      dataState: this.serverState!
    }
  }

  /**
   * 当前表单的模式
   */
  get formMode (): string {
    // 如果当前参数中没有带有表单状态 则从缓存中找
    let mode: string|undefined = this.$route.params[KEY_FORMMODE]

    if (!mode) mode = storage.loadCurPageMode()

    if (mode) return mode

    return 'view'
  }

  /**
   * 设置当前表单的编辑模式
   * @param mode
   */
  protected setFormMode (mode: string) {
    this.$route.params[KEY_FORMMODE] = mode
  }

  /**
   * 初始化表单的状态
  */
  protected onCreateFormState (): StateMachine<C, FormSchma, FormEvent> {
    const that = this
    const machine = Machine<C, FormSchma, FormEvent>({
      initial: this.formMode as any,
      states: {
        /// 新增状态
        new: {
          on: {
            insert: 'saving',
            reload: 'loading'
          },
          invoke: {
            src: (context, event) => {
              that.loading = true
              // 新增状态处理，如果第二次进行不清除数据
              const init = event.type === 'xstate.init'
              if (init) { return that.onNewFormData() } else {
                return new Promise<void>((resolve, reject) => resolve())
              }
            },
            onDone: { actions: 'loadingFinish' },
            onError: {
              actions: ['showLoadError', 'loadingFinish']
            }
          }
        },
        /// 修改状态
        edit: {
          on: {
            save: 'saving',
            reload: 'loading'
          },
          invoke: {
            src: () => {
              that.loading = true
              return that.onLoadFormDataFromServer()
            },
            onDone: { actions: 'loadingFinish' },
            onError: {
              actions: ['showLoadError', 'loadingFinish']
            }
          }
        },
        /// 查看状态
        view: {
          on: {
            edit: {
              target: 'edit',
              actions: () => {
                that.setFormMode('edit')
              }
            }
          },
          // 状态进行事件
          invoke: {
            src: () => {
              that.loading = true
              return that.onLoadFormDataFromServer()
            },
            onDone: { actions: 'loadingFinish' },
            onError: {
              actions: ['showLoadError', 'loadingFinish']
            }
          }
        },
        /// 保存中状态
        saving: {
          invoke: {
            src: (context, event) => {
              /// 检查是否为插入数据
              if (event.type === 'insert') {
                return new Promise<T>((resolve, reject) => {
                  this.onInsertFormData()
                    .then((dataItem) => {
                      const id = dataItem.id!
                      this.setCurPageDataId(id)
                      resolve(dataItem)
                    })
                })
              } else {
                return this.onSaveFormData()
              }
            },
            /// 保存完成后回到编辑模式
            onDone: {
              target: 'edit',
              actions: ['setToEdit', 'showSaveSucess', 'loadingFinish']
            },
            /// 当错误时，如果是插入引起的回到原来的new模块，否则回到edit
            onError: [{
              target: 'edit',
              actions: ['showSaveError', 'loadingFinish'],
              cond: (context, event, meta) => { return meta.state.history?.value !== 'new' }
            }, {
              target: 'new',
              actions: ['showSaveError', 'loadingFinish'],
              cond: (context, event, meta) => { return meta.state.history?.value === 'new' }
            }]
          }
        },
        /// 加载数据
        loading: {
          /// 使用快照，一进入即回到原来的状态
          always: [
            {
              target: 'new',
              cond: (context, event, meta) => {
                return meta.state.history?.value === 'new'
              }
            }, {
              target: 'edit',
              cond: (context, event, meta) => {
                return meta.state.history?.value === 'edit'
              }
            }
          ]

        }
      }
    }, {
      actions: {
        /// 显示加载错误
        showLoadError: (context, event: any) => {
          that.$Notice.error({
            title: '错误',
            desc: '加载数据错误:' + event.data
          })
        },
        /// 显示保存错误
        showSaveError: (context, event: any) => {
          that.$Notice.error({
            title: '错误',
            desc: '保存数据错误:' + event.data
          })
        },
        /// 保存数据成功
        showSaveSucess: (context, event: any) => {
          that.$Notice.success({
            title: '成功',
            desc: '数据保存成功'
          })
        },
        setLoading: (context, event: any) => {
          that.loading = true
        },
        loadingFinish: (context, event: any) => {
          that.loading = false
        },
        setToEdit: (context, event: any) => {
          that.setFormMode('edit')
          console.log('执行了修改edit')
        }
      }
    })

    return machine
  }

  /**
 * 加载表单的数据
 * @param stateConext 表单状态机的上下文对象
 */
  protected initFromState (stateConext: C) {
    // 创建表单的状态机
    const stateSerive = interpret(this.onCreateFormState().withContext(stateConext))
    storage.saveCurPageMode(this.formMode)
    expect(stateSerive, '表单状态机创建失败').not.undefined

    try {
      stateSerive.onTransition((state) => {
        _.set(this, 'formState', state)
        this.$set(this, 'formState', state)
        dispatchUpdateCheckTage(getterCurTagName(), this.formState?.value as string)
      }).start()

      this.formStateSerive = stateSerive as any
    } catch (error) {
      console.error('初始化状态机出错:' + (error as any)?.message, error)
    }
  }

  /**
   * 取得当前表单的状态
   */
  get stateValue () {
    return this.formState?.value
  }

  /**
 * 检查当前表单的状态是否是指定的状态
 * @param stateNames 例如 matchState('view') 或者 matchState({edit:'audit'}) 或 matchState('edit.audit')
 */
  matchState (...stateNames: any[]): boolean {
    if (!stateNames) return false
    if (stateNames.length! > 0) return false

    return this.formState?.matches(stateNames)!
  }

  /**
   * 打开表单对象
   * @param url 表单路径
   * @param mode 显示模式
   */
  protected reloadOpenFormPage (id: any, mode: string = FormState.View) {
    expect(id, '参数id不允许为空').not.undefined

    const route = {
      name: this.$route.name!,
      query: {
        [KEY_OPTION_PARAM]: cryptoTool.enCryptoData(id + '')
      },
      params: {
        [KEY_FORMMODE]: mode
      }

    }
    this.$router.replace(route)
  }
}
