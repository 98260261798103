
import { Component, Prop, Vue } from 'vue-property-decorator'
import PropertyModal from '@/views/project/webapi/entity-builder-system/components/property-panel/modal/property-modal.vue'
import { BaseModal } from '@/libs/modal-helper'
import { ApiGatewayRouteRule } from '@/api/maintain/api-gateway-route-rule'
import EnumSelect from '@/components/selector/enum-select.vue'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'
import GatewayRouteRuleType, { EnumItem } from '@/api/maintain/gateway-route-rule-type'
import moment from 'moment'
import HttpMethods from '@/api/maintain/http-method'

/// 新增环境变量模特框
@Component({
  name: 'addRouteRule',
  components: { PropertyModal, EnumSelect }
})
export default class AddRouteRuleModal extends Vue implements BaseModal {
  /// 修改时传入环境变量
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  item?: ApiGatewayRouteRule;

  /// 修改时传入环境变量
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  routeData?: ApiGatewayRoute;

  /// 是否只读
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly?: boolean;

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }

  /// 网关路由匹配规则类型
  gatewayRouteRuleType = GatewayRouteRuleType;

  /// 请求方法
  httpMethods = HttpMethods;

  /// 页面数据
  formData: ApiGatewayRouteRule = { ...this.item };
  /// 规则方法
  methods: string[]=[]

  // 选择时间
  selectTime: any = '';

  ruleKey=''

  ruleValue=''

  inputType = 'input';

  /// 由枚举值获取枚举项
  getEnumItemByValue (value: number): EnumItem | undefined {
    for (const key in this.gatewayRouteRuleType) {
      if (value === (this.gatewayRouteRuleType as any)[key].value) {
        return (this.gatewayRouteRuleType as any)[key]
      }
    }
  }

  /// 根据规则类型及规则值初始化时间选择器及枚举选择器
  created () {
    if (this.formData.ruleType === 1 || this.formData.ruleType === 2) {
      this.selectTime = moment(
        this.formData.ruleValue,
        'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'
      ).format('YYYY-MM-DD HH:mm:ss')
    }
    if (this.formData.ruleType === 3) {
      if (this.formData.ruleValue) {
        const strs = this.formData.ruleValue.split(',')
        const start = moment(strs[0], 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        const end = moment(strs[1], 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        this.selectTime = [start, end]
      } else {
        this.selectTime = []
      }
    }
    const item = this.getEnumItemByValue(this.formData.ruleType || 1)
    if (item) {
      this.inputType = item.type || 'input'
    }
    this.selectItem = item!
    if (this.inputType === 'input-kv') {
      this.ruleKey = this.formData.ruleValue?.split(',')[0] || ''
      this.ruleValue = this.formData.ruleValue?.split(',')[1] || ''
    }
    if (this.inputType === 'multiple') {
      this.methods = this.formData.ruleValue?.split(',') || []
    }
  }

  /// 匹配规则选择项
  selectItem: EnumItem = { name: '', value: 0, dest: '', type: 'input' };

  /// 路由名称校验
  async validateValue (rule: any, value: any, callback: Function) {
    if (this.inputType === 'input-kv') {
      this.formData.ruleValue = this.ruleKey + ',' + this.ruleValue
      if (this.ruleKey && this.ruleValue) {
        return callback()
      }
      return callback(new Error('key,value不能为空'))
    }
    if (this.inputType === 'multiple') {
      if (this.methods.length > 0) {
        return callback()
      } else {
        return callback(new Error('匹配规则不能为空'))
      }
    }
    if (!this.formData.ruleValue) {
      return callback(new Error('匹配规则不能为空'))
    }
    return callback()
  }

  /// 校验规则
  rules = {
    ruleType: [
      {
        required: true,
        type: 'number',
        message: '匹配规则类型不能为空',
        trigger: 'change'
      }
    ],
    ruleValue: {
      required: true,
      validator: this.validateValue
    }
  };

  /// 匹配规则类型选择项改变
  handleChange (data: EnumItem) {
    this.selectItem = data
    if (data.type === 'datetimerange') {
      this.selectTime = []
    } else if (data.type === 'datetime') {
      this.selectTime = ''
    }
    this.inputType = data.type || 'input'
    this.formData.ruleValue = undefined
  }

  /// 选择器格式化方法
  formatter (item: EnumItem) {
    return item.name + '-' + item.dest
  }

  /// 确认事件
  doSave () {
    if (this.inputType === 'input-kv') {
      this.formData.ruleValue = this.ruleKey + ',' + this.ruleValue
    }
    if (this.inputType === 'multiple') {
      this.formData.ruleValue = this.methods.join(',')
    }
    (this.$refs as any).formValidate.validate((valid: boolean) => {
      if (valid) {
        this.$emit('onOk', this.formData)
      } else {
        this.$Message.error('数据校验失败')
      }
    })
  }

  /// 处理选择时间改变
  handleTimeChange (data: any) {
    if (data instanceof Array) {
      const start = moment(data[0]).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
      const end = moment(data[1]).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
      this.formData.ruleValue = start + ',' + end
    } else {
      const timeStr = moment(Date.parse(data)).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
      this.formData.ruleValue = timeStr
    }
  }

  /// 取消事件
  doCancel () {
    this.$emit('onCancel')
  }
}
