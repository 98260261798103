
import { EnumDefine, EnumItem } from '@/api/maintain/gateway-route-rule-type'
import _ from 'lodash'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

interface SelectOption {
  label: string;
  value: number;
  id?: number | string;
  tag: string;
}

/**
 * 通用枚举选择器 指定可以选择的EnumDefine即可以使用
 */
@Component({
  name: 'EnumSelect'
})
export default class EnumSelect extends Vue {
  /**
   * 已选择值
   */
  @Prop({
    type: Number,
    required: true,
    default: -1000
  })
  readonly value?: number

  /**
   * 已选择值
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly disabled?: boolean

  /**
   * 可选择项 EnumDefine 类型
   */
  @Prop({
    type: Object,
    default: () => {
      return {}
    },
    required: true
  })
  readonly enumDefine!: EnumDefine

  @Prop({
    type: Number,
    required: false,
    default: 100
  })
  width!: number

  /**
   * placeholder
   */
  @Prop({
    type: String,
    default: '请选择',
    required: false
  })
  readonly placeholder!: string

  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  readonly clearable!: boolean

  // 让某项值不显示
  @Prop({
    type: [Number, Array],
    required: false
  })
  readonly disableValue?: number | number[]

  /**
   * 标题格式化程序
   */
  @Prop({
    type: Function,
    required: false
  })
  readonly formatter?: (item: EnumItem) => string

  enums: EnumDefine = {}

  // @Watch('disableValue')
  mounted () {
    this.onDisableValueChange()
    // if (this.disableValue) {
    //   this.enum = _.omitBy(this.enumDefine, (enumDefine: any) =>
    //     (Array.isArray(this.disableValue)
    //       ? this.disableValue
    //       : [this.disableValue]
    //     ).includes(enumDefine.value)
    //   )
    // } else this.enum = this.enumDefine
  }

  @Watch('disableValue')
  onDisableValueChange () {
    if (this.disableValue) {
      this.enums = _.omitBy(this.enumDefine, (enumDefine: any) =>
        (Array.isArray(this.disableValue)
          ? this.disableValue
          : [this.disableValue]
        ).includes(enumDefine.value)
      )
    } else this.enums = this.enumDefine
  }

  /** 清空选中的回调 */
  onClear () {
    this.$emit('input', undefined)
  }

  onChange (e: any) {
    this.$emit('on-change', e)
  }

  format (item: EnumItem): string {
    if (typeof this.formatter === 'function') {
      return this.formatter(item)
    } else {
      return item.dest
    }
  }

  /**
   * 选择事件
   */
  onSelect (selectValue: SelectOption) {
    this.$emit('input', selectValue.value)
    // 取得选择的值对应的完整枚举定义
    for (const item in this.enumDefine) {
      if (this.enumDefine[item].value === selectValue.value) {
        this.$emit('on-select', this.enumDefine[item])
        return
      }
    }
  }
}
