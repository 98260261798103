
import { showModal } from '@/libs/modal-helper'
import AddRouteModal from './add-route.vue'
import { ApiGatewayRoute } from '@/api/maintain/api-gateway-route'

/**
 * 显示新增环境变量模块框
 */
export default function showAddRouteModal (instanceId: number, gatewayId: string, ip: string, port: string, onOk?: (data: ApiGatewayRoute) => Promise<void>, routeData?: ApiGatewayRoute) {
  showModal<ApiGatewayRoute>(AddRouteModal, {
    props: {
      gatewayId: gatewayId,
      instanceId: instanceId,
      ip: ip,
      item: routeData,
      port: port
    }
  }, true, onOk, undefined)
}
