
export interface EnumItem{
    /// 枚举项的值
    value: number;
    /// 枚举项名称
    name: string;
    /// 描述
    dest: string;
    // 案例
    example?: string;
    /// 数据类型
    type?: string;
  }

/// 后台枚举的定义
export type EnumDefine = Record<string, EnumItem>

/// 网关路由匹配规则类型(Predicates)
const GatewayRouteRuleType = {
  Path: {
    value: 8,
    name: 'Path',
    type: 'input',
    dest: '根据指定路径转发uri',
    example: '支持通配符* 填写范例：/user/*。范例解释：将符合该路径的请求，转发到对应的uri。该范例表示，将访问网关的请求，http://gateway/user/1，转发到路由设定的uri上。注意，转发的uri，同样要有/user/*的路径'
  },
  Before: {
    value: 1,
    name: 'Before',
    type: 'datetime',
    dest: '在指定时间前,规则生效',
    example: '请选择时间，规则在该时间之前才会生效'
  },
  After: {
    value: 2,
    name: 'After',
    type: 'datetime',
    dest: '在指定时间后,规则生效',
    example: '请选择时间，规则在该时间之后才会生效'
  },
  Between: {
    value: 3,
    name: 'Between',
    type: 'datetimerange',
    dest: '在指定时间范围,规则生效',
    example: '请选择时间范围，规则在该时间范围才会生效'
  },
  Cookie: {
    value: 4,
    name: 'Cookie',
    type: 'input-kv',
    dest: '请求包含设置的Cookie信息,规则生效',
    example: '填写范例：fruit,apple。范例解释：有2个参数，分别是cookie的name，如范例中的fruit，以及一个匹配name的正则表达式，如范例中的apple，参数间用逗号隔开。该范例表示，当cookie包含fruit=apple，则该匹配规则生效'
  },
  Header: {
    value: 5,
    name: 'Header',
    type: 'input-kv',
    dest: '请求包含设置的Header信息,规则生效',
    example: '填写范例：X-Request-Id, 10。范例解释：有2个参数，分别是Header的key，如范例中的X-Request-Id，以及匹配X-Request-Id(key)的正则表达式，如范例中的10，参数间用逗号隔开。该范例表示，当Header包含X-Request-Id=10时候，则该匹配规则生效'
  },
  Host: {
    value: 6,
    name: 'Host',
    type: 'input',
    dest: '请求包含设置的Host信息,规则生效',
    example: '填写范例：**.example1.cn,**.example2.cn。范例解释：Host的头部信息中，包含指定uri（比如：www.example1.cn），则该匹配规则生效。有多个需要匹配的uri，则用逗号隔开，参考范例格式。'
  },
  Method: {
    value: 7,
    name: 'Method',
    type: 'multiple',
    dest: '指定请求方式,规则生效',
    example: '填写范例：GET,POST。范例解释：指定的请求方式，匹配规则才会生效。多个请求方式用英文逗号隔开'
  },
  Query: {
    value: 9,
    name: 'Query',
    type: 'input',
    dest: '包含指定请求参数名称，规则生效',
    example: '填写范例：name。范例解释：当请求中包含请求参数name，该匹配规则生效'
  },
  RemoteAddr: {
    value: 10,
    name: 'RemoteAddr',
    type: 'input',
    dest: '只对设置的ip地址,规则生效',
    example: '填写范例：192.168.1.1。范例解释：只有来之192.168.1.1这个IP的请求，规则才会生效'
  },
  // Weight: {
  //   value: 11,
  //   name: 'Weight',
  //   type: 'input',
  //   dest: '给路由设置权重值',
  //   example: '填写范例：group1,2。范例解释：该匹配规则一般需要2个或者多个路由规则搭配使用。group1表示当前路由规则在哪个weight组，2表示权重(20%)。只有同一个weight组，权限规则才会生效'
  // },
  XForwardedRemoteAddr: {
    value: 12,
    name: 'XForwardedRemoteAddr',
    type: 'input',
    dest: '只对设置的ip地址,规则生效',
    example: '填写范例：192.168.1.1。范例解释：请求header的XForwardedRemoteAddr属性中，包含192.168.1.1，规则生效'
  }
}

export default GatewayRouteRuleType
