
import { Component } from 'vue-property-decorator'
import DataFormPageView from '@/frame/share/data-form-page.vue'
import CollapsePanel from '@/components/collapse-panle.vue'
import StateFormItem from '@/components/state-formitem.vue'
import StateButton from '@/components/state-formbutton.vue'
import { Form } from 'view-design'
import apiGateWayApi, { ApiGateway } from '@/api/maintain/api-gateway'
import EmptyPage from '@/components/empty-page.vue'
import GateWayTable from './gateway-tables.vue'
import { getterUserInfo } from '@/store'
import StateDataFormPage from './state-form-page'

@Component({
  name: 'dataPage',
  components: {
    DataFormPageView,
    CollapsePanel,
    StateFormItem,
    StateButton,
    EmptyPage,
    GateWayTable
  }
})
export default class DataPage extends StateDataFormPage<ApiGateway, any> {
  protected serverState = '';

  loading = false;
  readonly = false;
  /**
   * 当前表单数据
   */
  formData: ApiGateway = {};

  /// 网关id
  gatewayId: string | undefined = '';
  /** 实例IP */
  ip = '';
  /** 端口 */
  port = '';

  tabName = 'gatewayRule';

  rulePageMode = 'view';

  instanceId: undefined | number = undefined;

  /**
   * 当前用户的项目编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateApiGateway
  }

  /// 网关规则名称校验
  async validateName (rule: any, value: string, callback: Function) {
    if (!value) {
      return callback(new Error('网关规则名称不能为空'))
    }
    const res = await apiGateWayApi.getItemsByNameByIp(
      this.ip,
      value,
      this.port,
      this.instanceId!
    )
    if (!res.data || res.data.length < 1) {
      return callback()
    }
    if (res.data.length === 1 && this.gatewayId && res.data[0].id === this.gatewayId) {
      return callback()
    }
    return callback(new Error('网关规则名称不能重复'))
  }

  /// 校验规则
  rules = {
    name: [
      {
        required: true,
        validator: this.validateName,
        trigger: 'blur'
      }
    ]
  };

  serverFormState: any = undefined;

  /// 网关路由列表
  // gateWayRoutes: ApiGatewayRoute[] = [];

  addRules = {
    name: [{ required: true, message: '应用名字不允许为空', trigger: 'blur' }]
  };

  created () {
    this.initFromState({})
    const extDataStr = this.getCurExtraData()
    if (extDataStr) {
      const extData = JSON.parse(extDataStr)
      this.ip = extData.ip
      this.port = extData.port
      this.instanceId = extData.instanceId
      this.setCurExtraData(extDataStr)
    }
    this.serverFormState = this.formStateSerive
    // this.loadRoute()
  }

  /** 设置页面为编辑状态 */
  setPageEdit () {
    this.rulePageMode = 'edit'
  }

  /**
   * 加载指定id的表单数据
   */
  onLoadFormData (id: string | number, extDataStr?: string): Promise<ApiGateway> {
    if (extDataStr) {
      const extData = JSON.parse(extDataStr)
      this.ip = extData.ip
      this.gatewayId = id as string
      this.port = extData.port
      this.instanceId = extData.instanceId
    }
    return new Promise<ApiGateway>((resolve, reject) => {
      apiGateWayApi
        .getItemByIdByIp(this.ip as string, this.gatewayId!, this.port, this.instanceId!)
        .then((response) => {
          resolve(response.data!)
        })
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 生成新增的表单数据
   */
  onCreateNewFormData (extDataStr?: string): Promise<ApiGateway> {
    let apigatewayGroupName = ''
    if (extDataStr) {
      const extData = JSON.parse(extDataStr)
      this.ip = extData.ip
      apigatewayGroupName = extData.apigatewayGroupName
    }
    return new Promise<ApiGateway>((resolve, reject) => {
      resolve({
        id: undefined,
        name: '',
        apigatewayGroupName: apigatewayGroupName
      })
    })
  }

  doFormValidate (callback: (valid?: boolean | undefined) => void) {
    (this.$refs.form as Form).validate((valid) => callback(valid))
  }

  /**
   * 执行新增数据保存
   */
  onInsertFormData (extDataStr?: string): Promise<ApiGateway> {
    this.formData.enable = false
    if (extDataStr) {
      const extData = JSON.parse(extDataStr)
      this.ip = extData.ip
      this.port = extData.port
    }
    return new Promise<ApiGateway>((resolve, reject) => {
      apiGateWayApi
        .insertItemByIp(this.ip, this.formData, this.port, this.instanceId!)
        .then((response) => {
          this.onLoadFormData(
            response.data!.id!,
            JSON.stringify({ ip: this.ip, port: this.port })
          )
          this.tabName = 'routeRule'
        })
        .catch((err: Error) => reject(err))
    })
  }

  /**
   * 保存修改数据
   */
  onSaveFormData (): Promise<ApiGateway> {
    return new Promise<ApiGateway>((resolve, reject) => {
      apiGateWayApi
        .updateItemByIp(this.ip, this.formData, this.port, this.instanceId!)
        .then((response) => {
          resolve(response.data!)
        })
        .catch((err: Error) => reject(err))
    })
  }
}
