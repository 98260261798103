/// 网络请求方法
const HttpMethods = {
  GET: {
    value: 1,
    name: 'GET',
    dest: 'GET'
  },
  POST: {
    value: 2,
    name: 'POST',
    dest: 'POST'
  },
  PUT: {
    value: 3,
    name: 'PUT',
    dest: 'PUT'
  },
  DELETE: {
    value: 4,
    name: 'DELETE',
    dest: 'DELETE'
  },
  PATCH: {
    value: 5,
    name: 'PATCH',
    dest: 'PATCH'
  },
  HEAD: {
    value: 6,
    name: 'HEAD',
    dest: 'HEAD'
  },
  OPTIONS: {
    value: 7,
    name: 'OPTIONS',
    dest: 'OPTIONS'
  }
}

export default HttpMethods
