/// 网关路由filter类型
const GatewayRouteFilterType = {
  AddRequestParamete: {
    value: 1,
    name: 'AddRequestParameter',
    dest: '添加请求参数',
    example: 'Request-paramer,paramer 表示将Request-paramer:paramer消息头添加到所有匹配请求的下游请求消息头中'
  },
  AddResponseHeader: {
    value: 2,
    name: 'AddResponseHeader',
    dest: '添加响应消息头',
    example: 'Reponse-paramer,paramer 表示将Reponse-paramer:paramer消息头添加到所有匹配请求的下游响应消息头中'
  },
  PrefixPath: {
    value: 3,
    name: 'PrefixPath',
    dest: '添加请求路径前缀',
    example: '/api 表示将/api作为所有匹配请求路径的前缀,将向/user 发送请求/api/user'
  },
  RewritePath: {
    value: 4,
    name: 'RewritePath',
    dest: '重写请求路径',
    example: '/foo/(?<segment>.*),/${' + 'segment' + '} 请求的路径/foo/**将被重写为/segment。其中，segment是一个变量，它将被替换为请求路径中/foo/后面的所有内容。例如，如果请求的路径是/foo/bar，则重写后的路径将是/bar。参数格式为：原始url表达式+","+改写之后的表达式'
  },
  StripPrefix: {
    value: 5,
    name: 'StripPrefix',
    dest: '剥离部分请求路径',
    example: '1 表示将请求发送到下游之前要从请求中剥离的路径中的部分数为1，将向/api/user 发送请求/user'
  }
}

export default GatewayRouteFilterType
