var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column","min-height":"600px"}},[(_vm.canEdit)?_c('div',{staticStyle:{"margin-bottom":"25px","display":"flex","justify-content":"end"}},[_c('Button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary"},on:{"click":_vm.addRoute}},[_vm._v("新增路由规则")]),_c('Button',{attrs:{"type":"default"},on:{"click":_vm.clearAll}},[_vm._v("清空所有规则")])],1):_vm._e(),_c('div',{staticStyle:{"flex":"1","display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"height":"300px","flex":"1"}},[_c('vxe-table',{ref:"xTable",attrs:{"expand-config":{
          accordion: true,
          expandRowKeys: _vm.expandRowKeys,
        },"row-config":{ keyField: 'id' },"show-overflow":"tooltip","height":"auto","border":"","stripe":"","auto-resize":"","data":_vm.gateWayRoutes},on:{"toggle-row-expand":_vm.rowExpand}},[_c('vxe-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"content",fn:function({ row }){return [_c('div',[_c('vxe-table',{attrs:{"data":_vm.tableData,"show-header":false,"expand-config":{
                  accordion: true,
                  expandRowKeys: _vm.expandRowKeys,
                }}},[_c('vxe-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"content",fn:function(irow){return [_c(irow.row.component,{tag:"component",attrs:{"ip":_vm.ip,"port":_vm.port,"instanceId":_vm.instanceId,"readonly":!_vm.canEdit,"routeData":row}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"title":"","field":"title","min-width":120}}),_c('vxe-table-column',{attrs:{"title":"","width":120},scopedSlots:_vm._u([{key:"default",fn:function(irow){return [_c('Tooltip',{attrs:{"content":irow.row.desc,"transfer":"","placement":"left"}},[_c('vxe-button',{attrs:{"type":"text","icon":"iconfont iconadd icon_color_normal","size":"16"},on:{"click":function($event){return _vm.addItem(row, irow.row.type)}}})],1)]}}],null,true)})],1)],1)]}}])}),_c('vxe-table-column',{attrs:{"title":"路由名称","field":"name","min-width":120}}),_c('vxe-table-column',{attrs:{"title":"转发uri","field":"uri","min-width":200}}),_c('vxe-table-column',{attrs:{"title":"备注","field":"memo","min-width":160}}),(_vm.canEdit)?_c('vxe-table-column',{attrs:{"title":"操作","min-width":120},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('Tooltip',{attrs:{"content":"修改路由","transfer":"","placement":"left"}},[_c('vxe-button',{attrs:{"type":"text","icon":"iconfont iconeditdoc icon_color_normal","size":"16"},on:{"click":function($event){return _vm.uptRoute(row)}}})],1),_c('Tooltip',{attrs:{"content":"删除路由","transfer":"","placement":"left"}},[_c('vxe-button',{attrs:{"type":"text","icon":"iconfont icondelete icon_color_normal","size":"16"},on:{"click":function($event){return _vm.delRoute(row)}}})],1)]}}],null,false,2848947920)}):_vm._e()],1)],1),_c('vxe-pager',{staticStyle:{"flex":"0"},attrs:{"layouts":[
        'Sizes',
        'PrevJump',
        'PrevPage',
        'Number',
        'NextPage',
        'NextJump',
        'FullJump',
        'Total',
      ],"current-page":_vm.queryBean.page,"page-size":_vm.queryBean.pageSize,"total":_vm.total},on:{"page-change":_vm.handlePageChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }