import { requestRawResponse, ApiResult, DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'

import { AppInstaceMangerRequestData } from './app-instance-manger'
import { DeployRequestApi } from './deploy-request'
import { Method } from 'axios'

/**
 * API网关
 */
export interface ApiGatewayRoute {

  id?: string;

  // 网关规则id
  gatewayId?: string;

  // 路由名称
  name?: string;

  // 路由转发的目的uri
  uri?: string;

  // 路由备注
  memo?: string;

  // 创建时间
  createTime?: string;

  // 修改时间
  modifyTime?: string;

}

/**
 * 团队服务请求api接口
 */
class ApiGatewayRouteApi {
  baseUrlPath='/api/maintain/gateway-instance/'

  /**
    * 通过查询bean查询数据
    * @param query 查询bean数据对象
    */
  public getItemByGateWayIdByIp (ip: string, id: string, port: string, instanceId: number): Promise<DataApiResult<ApiGatewayRoute[]>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRoute/byGateWayId/${id}`
    return this.encodeUrlRequest(instanceId, url, 'GET')
  }

  /**
   * 通过实例ip获取数据
   * @param query
   * @param ip
   * @returns
   */
  public queryByIp (query: PagerQueryBean, ip: string, port: string, instanceId: number): Promise<PageDataApiResult<ApiGatewayRoute>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRoute/query`
    return this.encodeUrlRequest(instanceId, url, 'POST', query)
  }

  /**
    * 通过查询路由名查询数据
    * @param name
    * @param 网关id
    */
  public getItemsByNameByIp (ip: string, name: string, id: string, port: string, instanceId: number): Promise<DataApiResult<ApiGatewayRoute[]>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRoute/byName/${id}/${encodeURIComponent(name)}`
    return this.encodeUrlRequest(instanceId, url, 'GET')
  }

  /**
    * 新增指定的数据
    * @param data
    */
  public insertItemByIp (ip: string, data: ApiGatewayRoute, port: string, instanceId: number): Promise<DataApiResult<ApiGatewayRoute>> {
    (data as any).id = undefined
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRoute/`
    return this.encodeUrlRequest(instanceId, url, 'PUT', data)
  }

  /**
    * 更新指定的数据
    * @param data 要删除数据对象
    */
  public updateItemByIp (ip: string, data: ApiGatewayRoute, port: string, instanceId: number): Promise<DataApiResult<ApiGatewayRoute>> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRoute/`
    return this.encodeUrlRequest(instanceId, url, 'POST', data)
  }

  /**
    * 删除id列表指定的数据
    * @param ids 记录主键列表
    */
  public deleteItemByIdsByIp (ip: string, ids: string[], port: string, instanceId: number): Promise<ApiResult> {
    const url = `${location.protocol}//${ip}:${port}/apigateway/apiGatewayRoute/`
    return this.encodeUrlRequest(instanceId, url, 'DELETE', ids)
  }

  /** gateway请求 */
  public encodeUrlRequest (instanceId: number, url: string, methods: Method, data?: any) {
    return new Promise<any>((resolve, reject) => {
      requestRawResponse<DataApiResult<AppInstaceMangerRequestData<any>>>({
        url: `${this.baseUrlPath}encodeRequestUrl/${instanceId}`,
        data: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        }
      }).then((res) => {
        requestRawResponse({
          url: url,
          method: methods,
          headers: {
            'Content-Type': 'application/json',
            Authorization: res.headers[DeployRequestApi.author_gateway_aukey]
          },
          data: data,
          timeout: 5000
        }, false).then((re) => {
          resolve(re.data)
        }).catch((err) => {
          reject(err)
        })
      })
    })
  }
}

export default new ApiGatewayRouteApi()
